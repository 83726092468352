import { Controller } from "stimulus"
import $ from 'jquery'

require("select2/dist/css/select2")
require("@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4")

import Select2 from "select2"

export default class extends Controller {
  connect() {
    $('.cities-select').select2({
      cities: true,
      theme: 'bootstrap4',
      placeholder: "Escolha sua cidade",
      allowClear: true

    });
  }
}